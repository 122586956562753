import { PolicyDeassignEntityType } from "../Services/Enum/EEmployeePolicyDetail";

const finalUrl: string = `${process.env.REACT_APP_BACKEND_URL}`;
//const finalUrl: string = `${"http://172.16.16.117:3082"}`;
const BASE_URL: string = `${finalUrl}/api/`;
export const URL_CONSTANTS = {
  generate_tokken: `${BASE_URL}auth/genrateToken`,
  changeAccountType: `${BASE_URL}auth/switch`,

  //On Board
  users_reister: `${BASE_URL}auth/register`,
  login_via_username_password: `${BASE_URL}auth/login`,
  get_user_parent_user_data: `${BASE_URL}auth/get-parents-users`,
  get_employer_user_data: `${BASE_URL}auth/employer/users`,
  forgot_password: `${BASE_URL}auth/forget-password`,
  reset_password: `${BASE_URL}auth/reset-password`,
  delete_user: `${BASE_URL}auth/logout`,
  user_logout_employer: `${BASE_URL}auth/employer/logout`,
  user_logout_employee: `${BASE_URL}auth/employee/logout`,
  get_user: `${BASE_URL}auth/broker/check`,
  get_user_employer: `${BASE_URL}auth/employer/check`,
  get_user_employee: `${BASE_URL}auth/employee/check`,

  // user
  user_edit_get_data_by_id: `${BASE_URL}auth/users?id=`,
  user_update_data_by_id: `${BASE_URL}auth/update-user-details`,
  user_delete: `${BASE_URL}auth/users`,
  // User Role
  get_user_role: `${BASE_URL}auth/users-role-dropdown`,
  get_user_role_employer: `${BASE_URL}auth/employer/users-role-dropdown`,
  post_user_role_data: `${BASE_URL}auth/users-role`,
  post_user_role_data_employer: `${BASE_URL}auth/employer/users-role`,
  user_change_status: `${BASE_URL}auth/users-status-update?id=`,
  get_user_status: `${BASE_URL}global/status/user`,
  update_user_status: `${BASE_URL}broker/user/change/status?id=`,

  // acces control
  get_access_control_data: `${BASE_URL}auth/get-users-role`,
  delete_access_control_data: `${BASE_URL}auth/users-role`,
  get_user_access_control_data_by_id: `${BASE_URL}auth/users-role?id=`,
  user_access_control_update_data: `${BASE_URL}auth/update-user-role-details`,
  get_access_control_status: `${BASE_URL}global/status/access_control`,
  update_access_control_status: `${BASE_URL}auth/role-status?id=`,
  // user branch department dropdown list api
  get_branch_dropdown_list: `${BASE_URL}broker/branch-dropdown-list`,
  get_department_dropdown_list: `${BASE_URL}broker/department-dropdown-list`,
  get_branch_status: `${BASE_URL}global/status/branch`,
  get_department_status: `${BASE_URL}global/status/department`,
  get_texation_status: `${BASE_URL}global/status/texation`,
  update_branch_status: `${BASE_URL}broker/settings-branch-status?id=`,
  update_department_status: `${BASE_URL}broker/settings-department-status?id=`,
  update_texation_status: `${BASE_URL}broker/tax-rates/change-status?id=`,
  //update_mail_config_settings: `${BASE_URL}broker/mail`,
  // test_mail_config_settings: (id: string) =>
  //   `${BASE_URL}broker/mail-check?id=${id}`,
  test_mail_config_settings:`${BASE_URL}broker/mail-check`,
  get_storage_config_settings: `${BASE_URL}broker/storage-config-dropdown`,
  update_storage_config_settings: (user_id: any, setting_id: any) =>
    `${BASE_URL}broker/apply-settings?user_id=${user_id}&setting_id=${setting_id}&apply_for=STORAGE`,
  get_storage_config_data: (id: any) => `${BASE_URL}broker/storage?id=${id}`,
  update_storage_config_details: `${BASE_URL}broker/storage/config`,
  test_storage_config_settings: `${BASE_URL}broker/storage-check`,

  //employer user branch department dropdown list api
  get_employer_branch_dropdown_list: `${BASE_URL}employer/branch-dropdown-list`,
  get_employer_department_dropdown_list: `${BASE_URL}employer/department-dropdown-list`,
  // contact layout form
  get_contact_form_layout: `${BASE_URL}broker/contact-module`,
  get_contact_list: (
    limit: number,
    startIndex: number,
    starDate: Date,
    endDate: Date,
    searchValue: string
  ) =>
    `${BASE_URL}broker/contacts-list-view-v2?limit=${limit}&skip=${startIndex}&startDate=${starDate}&endDate=${endDate}&searchValue=${searchValue}`,
  get_contact_header_field: `${BASE_URL}broker/meta-data`,
  massmodification: `${BASE_URL}broker/contact-mass-update-field-view`,
  updateMassmodification: `${BASE_URL}broker/contact-mass-update-data`,
  updateClaimMassmodification: `${BASE_URL}broker/bulkupdate-claim-status`,
  add_new_contact: `${BASE_URL}broker/contacts`,
  detail_contact: `${BASE_URL}broker/contacts-detail-view?id=`,
  delete_contact: `${BASE_URL}broker/contact?id=`,
  delete_multiple_contacts: `${BASE_URL}broker/contacts`,
  status_change: `${BASE_URL}broker/change-status?id=`,
  edit_contact: `${BASE_URL}broker/contacts?id=`,
  individual_edit: `${BASE_URL}broker/contact-indvidual-edit?id=`,
  load_contacts_field_data_for_bulk_upload: `${BASE_URL}broker/contact-upload-field-view`,
  bulk_upload_contact: `${BASE_URL}broker/contact-bulk-upload-data?code=`,
  // industry listing
  get_industry_list: `${BASE_URL}global/industry-list`,
  get_subindustry_list: `${BASE_URL}global/sub-industry-list?id=`,
  // country listing // global api
  get_country_list: `${BASE_URL}global/countries`,
  get_state_list: `${BASE_URL}global/states?country=`,
  get_city_list: `${BASE_URL}global/cities?`,
  get_pincode_list: `${BASE_URL}global/validate_pincode`,
  dynamic_status_list: `${BASE_URL}global/dynamic-status-list`,
  time_zone_list: `${BASE_URL}global/time-zone`,
  currency_list: `${BASE_URL}global/currency`,
  get_client_location: `${BASE_URL}broker/get-selected-client-info?id=`,
  get_dep_designation_salary: (user: string) =>
    `${BASE_URL}${user}/organisation-structure-data`,
  //contactLayout
  get_contact_layout: `${BASE_URL}broker/contact-module`,
  post_contact_layout: `${BASE_URL}broker/contact-module`,
  get_contact_custom_module_archive_data: `${BASE_URL}broker/contact-module-archive-data`,
  post_contact_custom_module_archive_data: `${BASE_URL}broker/contact-module-archive-data`,
  contact_notes: `${BASE_URL}broker/contact-note?id=`,
  get_contact_notes: `${BASE_URL}broker/contact-note?id=`,
  //clientLayout
  get_client_layout: `${BASE_URL}broker/client-module`,
  post_client_layout: `${BASE_URL}broker/client-module`,
  post_client_custom_module_archive_data: `${BASE_URL}broker/client-module-archive-data`,
  get_client_custom_module_archive_data: `${BASE_URL}broker/client-module-archive-data`,
  get_client_indivisual_edit: `${BASE_URL}broker/client-indvidual-edit?id=`,
  get_client_details_subsidiary: `${BASE_URL}broker/client-add-subsidiary`,
  Is_subsidiary: `${BASE_URL}broker/toggle-subsidiary?id=`,
  check_isclient_attached_toPolicy: `${BASE_URL}broker/check-if-policy-is-attached-to-client?id=`,
  get_policy_data: (limit: number, skip: number, id: string) =>
    `${BASE_URL}broker/client-policy-list?limit=${limit}&skip=${skip}&id=${id}`,

  massmodificationclient: `${BASE_URL}broker/client-mass-update-field-view`,
  updateMassmodificationclient: `${BASE_URL}broker/client-mass-update-data`,

  //EmployeeLayout
  get_employee_layout: `${BASE_URL}broker/employee-module`,
  checkIsMailUsed: (mail: string, userType: string, type: string) =>
    `${BASE_URL}${userType}/email-check/${mail}?type=${type}`,
  get_employee_detail_layout: `${BASE_URL}broker/employee-detail-view?id=`,
  update_employee_detail_global: `${BASE_URL}broker/employee?id=`,
  individual_edit_employee_detail: (id: string, usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/employee-indvidual-edit?id=${id}`,
  post_employee_layout: `${BASE_URL}broker/employee-module`,
  post_employee_custom_module_archive_data: `${BASE_URL}broker/employee-module-archive-data`,
  get_employee_custom_module_archive_data: `${BASE_URL}broker/employee-module-archive-data`,
  employee_status_change: (id: string, type: string, usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/employee-status?id=${id}&type=${type}`,
  policy__enroll_status_change: (
    userType: string,
    id: string,
    childId: string
  ) =>
    `${BASE_URL}${userType}/enrolment-status?parent_id=${id}&child_id=${childId}`,
  policy__enroll_dependent_status_change: (
    id: string,
    childId: string,
    policyId: string,
    userType: string
  ) =>
    `${BASE_URL}${userType}/enrolment-dependent-status?parent_id=${id}&child_id=${childId}&policyId=${policyId}`,
  update_status_nominee: (
    id: string,
    policyId: string,
    memberId: string,
    usertypeinfo: string
  ) =>
    `${BASE_URL}${usertypeinfo}/enrolment-nominee-status?id=${id}&policyId=${policyId}&memberId=${memberId}`,
  get_employee_assigned_policies: (empId: string) =>
    `${BASE_URL}broker/dependant-policy-list-view?id=${empId}`,
  massmodificationemployee: `${BASE_URL}broker/employee-mass-update-field-view`,
  updateMassmodificationemployee: `${BASE_URL}broker/employee-mass-update-data`,
  get_endorsement_list: `${BASE_URL}broker/employee/endorsment?id=`,
  get_claims_list: `${BASE_URL}broker/employee/claim?id=`,
  checkIsRelationExist: `${BASE_URL}global/relation-exists?`,

  //product configruator group health category
  create_group_health_catageory: `${BASE_URL}broker/product/createGroupHealthCategory`,
  get_group_health_catageory: `${BASE_URL}broker/product/getAllGroupHealthCategories?searchValue=`,
  delete_group_health_catageory: `${BASE_URL}broker/product/deleteGroupHealthCategories`,
  check_is_feature_assigned: `${BASE_URL}broker/check-is-feature-attached-to-policy?id=`,
  check_is_exclusion_assigned: `${BASE_URL}broker/check-is-exclustion-attached-to-policy?id=`,

  update_group_health_catageory: `${BASE_URL}broker/product/updateGroupHealthCategory?id=`,
  //product configruator group health exculsions
  get_group_health_exclusion: `${BASE_URL}broker/product/getAllGroupHealthExclusions?searchValue=`,
  create_group_health_exclusion: `${BASE_URL}broker/product/createGroupHealthExclusion`,
  update_group_health_exclusions: `${BASE_URL}broker/product/updateGroupHealthExclusion?id=`,
  delete_group_health_exclusions: `${BASE_URL}broker/product/deleteGroupHealthExclusions`,
  //product configruator group health feature
  get_group_health_feature: `${BASE_URL}broker/product/getAllGroupHealthFeatures?searchValue=`,
  create_group_health_feature: `${BASE_URL}broker/product/createGroupHealthFeature`,
  update_group_health_feature: `${BASE_URL}broker/product/updateGroupHealthFeature?id=`,
  delete_group_health_feature: `${BASE_URL}broker/product/deleteGroupHealthFeatures`,

  //product configruator super topup feature
  get_super_topup_feature: `${BASE_URL}broker/product/getAllSuperTopUpsFeatures?searchValue=`,
  create_super_topup_feature: `${BASE_URL}broker/product/createSuperTopUpFeatures`,
  update_super_topup_feature: `${BASE_URL}broker/product/updateSuperTopUpFeatures?id=`,
  delete_super_topup_feature: `${BASE_URL}broker/product/deleteSuperTopUpsFeatures`,
  //product configruator super topup category
  create_super_topup_catageory: `${BASE_URL}broker/product/createSuperTopUpCategories`,
  get_super_topup_catageory: `${BASE_URL}broker/product/getAllSuperTopUpsCategories?searchValue=`,
  delete_super_topup_catageory: `${BASE_URL}broker/product/deleteSuperTopUpsCategories`,
  update_super_topup_catageory: `${BASE_URL}broker/product/updateSuperTopUpCategories?id=`,

  //product configruator super topup exculsions
  get_super_topup_exclusion: `${BASE_URL}broker/product/getAllSuperTopUpsExclusion?searchValue=`,
  create_super_topup_exclusion: `${BASE_URL}broker/product/createSuperTopUpExclusion`,
  update_super_topup_exclusions: `${BASE_URL}broker/product/updateSuperTopUpExclusion?id=`,
  delete_super_topup_exclusions: `${BASE_URL}broker/product/deleteSuperTopUpsExclusion`,

  // product configruator group term category
  create_group_term_catageory: `${BASE_URL}broker/product/createGroupTermCategory`,
  get_group_term_catageory: `${BASE_URL}broker/product/getAllGroupTermCategories?searchValue=`,
  update_group_term_catageory: `${BASE_URL}broker/product/updateGroupTermCategory?id=`,
  delete_group_term_catageory: `${BASE_URL}broker/product/deleteGroupTermCategories`,
  // product configurator group term feature
  get_group_term_feature: `${BASE_URL}broker/product/getAllGroupTermFeatures?searchValue=`,
  create_group_term_feature: `${BASE_URL}broker/product/createGroupTermFeature`,
  delete_group_term_feature: `${BASE_URL}broker/product/deleteGroupTermFeatures`,
  update_group_term_feature: `${BASE_URL}broker/product/updateGroupTermFeature?id=`,
  // product configruator group term EXCLUSIONS
  get_group_term_exclusion: `${BASE_URL}broker/product/getAllGroupTermExclusions?searchValue=`,
  create_group_term_exclusion: `${BASE_URL}broker/product/createGroupTermExclusion`,
  update_group_term_exclusion: `${BASE_URL}broker/product/updateGroupTermExclusion?id=`,
  delete_group_term_exclusion: `${BASE_URL}broker/product/deleteGroupTermExclusions`,
  // product configruator group personal accident categegory
  get_group_personal_accident_catageory: `${BASE_URL}broker/product/getAllGroupPersonalAccidentCategories?searchValue=`,
  create_group_personal_accident_catagory: `${BASE_URL}broker/product/createGroupPersonalAccidentCategory`,
  update_group_personal_accident_catagory: `${BASE_URL}broker/product/updateGroupPersonalAccidentCategory?id=`,
  delete_group_personal_accident_catagory: `${BASE_URL}broker/product/deleteGroupPersonalAccidentCategories`,
  // product configurator group personal accident category
  get_group_personal_accident_feature: `${BASE_URL}broker/product/getAllGroupPersonalAccidentFeatures?searchValue=`,
  create_group_personal_accident_feature: `${BASE_URL}broker/product/createGroupPersonalAccidentFeature`,
  update_group_personal_accident_feature: `${BASE_URL}broker/product/updateGroupPersonalAccidentFeature?id=`,
  delete_group_personal_accident_feature: `${BASE_URL}broker/product/deleteGroupPersonalAccidentFeatures`,
  // product configruator group personal accident EXCLUSIONS
  get_group_personal_accident_exclusion: `${BASE_URL}broker/product/getAllGroupPersonalAccidentExclusions?searchValue=`,
  create_group_personal_accident_exclusion: `${BASE_URL}broker/product/createGroupPersonalAccidentExclusion`,
  update_group_personal_accident_exclusion: `${BASE_URL}broker/product/updateGroupPersonalAccidentExclusion?id=`,
  delete_group_personal_accident_exclusion: `${BASE_URL}broker/product/deleteGroupPersonalAccidentExclusions`,
  // product configruator group criticalines categegory
  get_group_criticalilness_catageory: `${BASE_URL}broker/product/getAllGroupCriticalIllnessCategories?searchValue=`,
  create_group_criticalilness_catageory: `${BASE_URL}broker/product/createGroupCriticalIllnessCategory`,
  update_group_criticalilness_catageory: `${BASE_URL}broker/product/updateGroupCriticalIllnessCategory?id=`,
  delete_group_criticalilness_catageory: `${BASE_URL}broker/product/deleteGroupCriticalIllnessCategories`,
  // product configurator group criticalines feature
  get_group_criticalilness_feature: `${BASE_URL}broker/product/getAllGroupCriticalIllnessFeatures?searchValue=`,
  create_group_criticalilness_feature: `${BASE_URL}broker/product/createGroupCriticalIllnessFeature`,
  update_group_criticalilness_feature: `${BASE_URL}broker/product/updateGroupCriticalIllnessFeature?id=`,
  delete_group_criticalilness_feature: `${BASE_URL}broker/product/deleteGroupCriticalIllnessFeatures`,
  // product configruator group criticalines Exclusion
  get_group_criticalilness_exclusion: `${BASE_URL}broker/product/getAllGroupCriticalIllnessExclusions?searchValue=`,
  create_group_criticalilness_exclusion: `${BASE_URL}broker/product/createGroupCriticalIllnessExclusion`,
  update_group_criticalilness_exclusion: `${BASE_URL}broker/product/updateGroupCriticalIllnessExclusion?id=`,
  delete_group_criticalilness_exclusion: `${BASE_URL}broker/product/deleteGroupCriticalIllnessExclusions`,
  // product configruator group travelinsurance categegory
  get_group_travel_catageory: `${BASE_URL}broker/product/getAllGroupTravelCategories?searchValue=`,
  create_group_travel_catageory: `${BASE_URL}broker/product/createGroupTravelCategory`,
  update_group_travel_catageory: `${BASE_URL}broker/product/updateGroupTravelCategory?id=`,
  delete_group_travel_catageory: `${BASE_URL}broker/product/deleteGroupTravelCategories`,
  // product configruator group travelinsurance feature
  get_group_travel_feature: `${BASE_URL}broker/product/getAllGroupTravelFeatures?searchValue=`,
  create_group_travel_feature: `${BASE_URL}broker/product/createGroupTravelFeature`,
  update_group_travel_feature: `${BASE_URL}broker/product/updateGroupTravelFeature?id=`,
  delete_group_travel_feature: `${BASE_URL}broker/product/deleteGroupTravelFeatures`,
  // product configruator group travelinsurance exclusions
  get_group_travel_exclsuion: `${BASE_URL}broker/product/getAllGroupTravelExclusions?searchValue=`,
  create_group_travel_exclusion: `${BASE_URL}broker/product/createGroupTravelExclusion`,
  update_group_travel_exclusion: `${BASE_URL}broker/product/updateGroupTravelExclusion?id=`,
  delete_group_travel_exclusion: `${BASE_URL}broker/product/deleteGroupTravelExclusions`,
  //product configurator add insurer
  create_add_insurer: `${BASE_URL}broker/product/createInsurance`,
  create_add_tpa: `${BASE_URL}broker/product/createTpa`,

  get_spocList: `${BASE_URL}broker/spoc-list`,
  get_insurer: `${BASE_URL}broker/product/getInsurance?type=`,
  get_Tpa: `${BASE_URL}broker/product/allTpas?`,
  add_to_list: `${BASE_URL}broker/product//add-to-list?`,
  get_Integrated_Tpa: `${BASE_URL}broker/product/integrated_tpa_dropdown`,
  delete_insurer: `${BASE_URL}broker/product/deleteInsurance`,
  delete_Tpa: `${BASE_URL}broker/product/tpa-delete`,
  update_insurer: `${BASE_URL}broker/product/patchInsurerance?id=`,
  update_tpa: `${BASE_URL}broker/product/tpa-update?id=`,
  individual_edit_insurer: `${BASE_URL}broker/product/insurance-indvidual-edit?id=`,
  individual_edit_tpa: `${BASE_URL}broker/product/tpa-individual-edit?id=`,
  individual_delete: `${BASE_URL}broker/product/insurance-delete-item?insurenceId=`,
  individual_delete_tpa: `${BASE_URL}broker/product/tpa-individual?tpaId=`,
  location_delete: `${BASE_URL}broker/product/insurance-delete-location?`,
  location_delete_tpa: `${BASE_URL}broker/product/tpa-location?`,
  load_prod_config_insurer_field_data_for_bulk_upload: `${BASE_URL}broker/product/insurance-upload-field-view`,
  bulk_upload_product_config_insurer: `${BASE_URL}broker/product/insurance-config-bulk-upload?code=`,
  check_is_Insurer_attached: `${BASE_URL}broker/check-is-insurer-attached-to-policy?id=`,
  // client api url
  getHeaderData: `${BASE_URL}broker/client-meta-data`,
  getClientRowData: (
    limit: number,
    skip: number,
    starDate: any,
    endDate: any,
    searchValue: any
  ) =>
    `${BASE_URL}broker/client-list-view?limit=${limit}&skip=${skip}&startDate=${starDate}&endDate=${endDate}&searchValue=${searchValue}`,
  get_detail_view: `${BASE_URL}broker/product/getOneInsurance?id=`,
  get_detail_view_tpa: (id: any, usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/product/tpa-detail?id=${id}`,
  add_new_client: `${BASE_URL}broker/client`,
  delete_clients: `${BASE_URL}broker/clients`,
  get_one_client: `${BASE_URL}broker/client-detail-view?id=`,
  update_one_client: `${BASE_URL}broker/client?id=`,
  get_spoc: `${BASE_URL}broker/spoc-list`,

  get_parent_details: `${BASE_URL}broker/client-get-subsidiary?id=`,
  delete_attachment: `${BASE_URL}broker/client-attatchemnt`,
  delete_location: `${BASE_URL}broker/client-location`,
  client_status: `${BASE_URL}broker/client-status?clientId=`,
  client_contact_list: `${BASE_URL}broker/client-contact-list?id=`,
  client_subsidary_list: `${BASE_URL}broker/client-subsidry-childs?id=`,
  client_delete_details_location: `${BASE_URL}broker/client-location`,
  client_field_data_for_bulk_upload: `${BASE_URL}broker/client-upload-field-view`,
  bulk_upload_client: `${BASE_URL}broker/client-bulk-upload-data?code=`,
  csv_download_client: (user: string, model: string) =>
    `${BASE_URL}${user}/download_csv?model=${model}`,

  // employee api url
  get_e_cards: (user: string) => `${BASE_URL}${user}/ecards?policy_id=`,
  get_ClaimForm: (user: string,insurerId:string,policyId:string) => `${BASE_URL}${user}/physical-forms?id=${insurerId}&policyId=${policyId}`,
  get_employees_by_clientId: `${BASE_URL}broker/employee-dropdown-list?`,
  create_employee: `${BASE_URL}broker/employee`,
  get_client_list: (user: string) => `${BASE_URL}${user}/client-dropdown-list`,
  get_insurer_list: (user: string) =>
    `${BASE_URL}${user}/company-insurer-dropdown`,
  get_employer_client_list: `${BASE_URL}employer/client-dropdown-list`,
  get_client_location_list: `${BASE_URL}broker/client-location-dropdown-list?id=`,
  get_header_tag: `${BASE_URL}broker/employee-meta-data`,
  get_table_data_employee: (
    limit: number,
    skip: number,
    starDate: any,
    endDate: any,
    searchValue: any
  ) =>
    `${BASE_URL}broker/employee-list-view?limit=${limit}&skip=${skip}&startDate=${starDate}&endDate=${endDate}&searchValue=${searchValue}`,
  delete_employee: `${BASE_URL}broker/employees`,
  bulk_upload_member: (user: string) =>
    `${BASE_URL}${user}/${
      user === "broker" ? "employee" : "member"
    }-bulk-upload-data?code=`,
  member_field_data_for_bulk_upload: (user: string) =>
    `${BASE_URL}${user}/${
      user === "broker" ? "employee" : "member"
    }-upload-field-view?key=`,
  updateAddress: (usertypeinfo: string, id: string) =>
    `${BASE_URL}${usertypeinfo}/employee-address?id=${id}`,
  memberlistAccordingPolicy: (
    id: string,
    usertypeinfo: string,
    limit: number,
    skip: number
  ) =>
    `${BASE_URL}${usertypeinfo}/policy-tab-view?id=${id}&tab=MEMBER&limit=${limit}&skip=${skip}`,

  // employee profile family api url
  get_family_detail: (id: string, userType: string) =>
    `${BASE_URL}${userType}/employee-family-member?employeeId=${id}`,
  create_family_detail: (usertypeinfo: any) =>
    `${BASE_URL}${usertypeinfo}/employee-family-member`,
  update_family_detail: (usertypeinfo: string, id: any, memberId: any) =>
    `${BASE_URL}${usertypeinfo}/employee-family-member?employeeId=${id}&memberId=${memberId}`,
  delete_family_detail: (id: any, remian: string, usertypeinfo: any) =>
    `${BASE_URL}${usertypeinfo}/employee-family-details?employeeId=${id}${remian}`,
  // employee profile kyc family api url
  get_family_kyc_detail: (id: any, memberId: string, usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/employee-family-member-kyc?employeeId=${id}&memberId=${memberId}`,
  create_family_kyc_detail: (usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/employee-family-member-kyc`,

  // employee profile vehcile detail api url
  create_vehicle_detail: (user: string) =>
    `${BASE_URL}${user}/employee-vehicle-details`,
  get_vehicle_detail: `${BASE_URL}broker/employee-vehicle-details?employeeId=`,
  delete_vehicle_delete: `${BASE_URL}broker/employee-vehicle-details?id=`,
  GENERIC_vehicle_delete: (user: string) => `${BASE_URL}${user}/employee-vehicle-details`,
  update_vehicle_detail: (user: string) =>
    `${BASE_URL}${user}/employee-vehicle-details?id=`,
  // employee profile vehicle detail api url of the dropdown list
  get_bike_make_model: `${BASE_URL}global/get-tw-make-modal`,
  get_bike_fuel_type: `${BASE_URL}global/get-tw-fuel-type?make_model`,
  get_car_make_model: `${BASE_URL}global/get-car-make-modal`,
  get_car_fuel_type: `${BASE_URL}global/get-car-fuel-type?make_model`,
  get_car_variant: `${BASE_URL}global/get-car-variant-list-details?make_model`,
  get_bike_variant: `${BASE_URL}global/get-tw-variant-list-details?make_model`,
  // employee profile property detail api url
  create_property_detail: (user: string) =>
    `${BASE_URL}${user}/employee-property`,
  get_property_detail: (user: string) =>
    `${BASE_URL}${user}/employee-property?id=`,
  delete_property_detail: `${BASE_URL}broker/employee-property?id=`,
  GENERIC_delete_property: (user: string) => `${BASE_URL}${user}/employee-property`,
  update_property_detail: (user: string) =>
    `${BASE_URL}${user}/employee-property?id=`,
  // employee profile kyc detail api url
  create_kyc_detail: `${BASE_URL}broker/employee-kyc-details`,
  get_kyc_detail: (id: any, usertypeinfo: any) =>
    `${BASE_URL}${usertypeinfo}/employee-kyc-details?employeeId=${id}`,
  delete_kyc_detail:(kyc:string,emp:string, )=> `${BASE_URL}broker/employee-kyc-details?id=${kyc}&employeeId=${emp}`,
  download_data: `${BASE_URL}global/download?item=`,
  //employee nomine api url
  create_nominee: (user: string) => `${BASE_URL}${user}/employee-nominee`,
  get_nominee: `${BASE_URL}broker/employee-nominee?id=`,
  update_nominee: `${BASE_URL}broker/employee-nominee?id=`,
  nominee_delete: `${BASE_URL}broker/employee-nominee?id=`,
  // employee nomine health e card
  create_health_ecard: `${BASE_URL}broker/employee-ecard`,
  get_health_card: `${BASE_URL}broker/employee-ecard?id=`,
  update_health_card: `${BASE_URL}broker/employee-ecard?id=`,
  health_delete: `${BASE_URL}broker/employee-ecard?id=`,

  //Custome module

  get_stages_status: `${BASE_URL}broker/stagesStatus`,
  post_stages: `${BASE_URL}broker/stagesStatus`,
  post_status_data: `${BASE_URL}broker/stagesStatus`,
  delete_status_or_stages: `${BASE_URL}broker/stagesStatus`,
  get_single_stage_status: `${BASE_URL}broker/stagesStatus-detail-view`,
  update_stage_status: `${BASE_URL}broker/stagesStatus`,
  get_summary_deatils: `${BASE_URL}broker/summary`,

  //Branches
  create_branch: `${BASE_URL}broker/settings-branch`,
  create_employer_branch: `${BASE_URL}employer/settings-branch`,
  get_branch_list: `${BASE_URL}broker/settings-branch`,
  branch_detail_view: `${BASE_URL}broker/settings-branch-detail-view`,
  update_branch: `${BASE_URL}broker/settings-branch`,
  delete_branches: `${BASE_URL}broker/settings-branch`,

  //department
  create_department: `${BASE_URL}broker/settings-department`,
  get_department_list: `${BASE_URL}broker/settings-department`,
  department_detail_view: `${BASE_URL}broker/settings-department-detail-view`,
  update_department: `${BASE_URL}broker/settings-department`,
  delete_departments: `${BASE_URL}broker/settings-department`,

  get_personal_settings: `${BASE_URL}broker/personal-settings`,
  update_personal_settings: `${BASE_URL}broker/personal-settings`,
  get_cutom_module_modified_dates: `${BASE_URL}broker/get-cutom-module-modified-dates`,
  department_list: `${BASE_URL}broker/department-dropdown-list`,

  update_password: `${BASE_URL}broker/change-password-personal-settings`,
  update_password_broker: (usertype: string) =>
    `${BASE_URL}${usertype}/user/change-password-personal-settings`,
  //organisation Details
  organisation_details: (usertype: string) =>
    `${BASE_URL}${usertype}/organization-details`,
  update_organisation_details: `${BASE_URL}broker/update-organization-details`,

  //Organisation Tax Settings
  create_basic_info: `${BASE_URL}broker/tax-rates`,
  get_basic_info: (apiType: string, orgTaxId: string) =>
    `${BASE_URL}broker/tax-rates/list?APITYPE=${apiType}&taxId=${orgTaxId}`,
  update_basic_info: (parent_id: string) =>
    `${BASE_URL}broker/tax-rates/list?id=${parent_id}`,
  get_tax_rates_list: () =>
    `${BASE_URL}broker/tax-rates/list-view?limit=100&skip=0&sort=1&search=`,
  add_tax_rate: `${BASE_URL}broker/tax-rates`,
  delete_tax_rate: (parent_id: string, childId: string) =>
    `${BASE_URL}broker/tax-rates/list?id=${parent_id}&childId=${childId}`,
  update_tax: (parent_id: string, taxId: string) =>
    `${BASE_URL}broker/tax-rates/list?id=${parent_id}&childId=${taxId}`,
  get_tax_type_options: `${BASE_URL}global/tax-type-dropdown-list`,

  //policy
  insurance_name_dropdownlist: `${BASE_URL}broker/insurance-name-dropdownlist?`,
  tpa_name_dropdownlist: `${BASE_URL}broker/tpa-dropdown-list`,
  insurance_address_dropdownlist: `${BASE_URL}broker/insurance-address-dropdownlist?id=`,
  features_list: `${BASE_URL}broker/features-list?code=`,
  delete_attachment_policy: `${BASE_URL}broker/policy-attachment?attachmentsId=`,
  check_ispolicy_attached_toMember: `${BASE_URL}broker/check-is-policy-attached-to-members?id=`,
  get_policy_list: (
    limit: number,
    skip: number,
    starDate: any,
    endDate: any,
    searchValue: any
  ) =>
    `${BASE_URL}broker/policy?limit=${limit}&skip=${skip}&startDate=${starDate}&endDate=${endDate}&searchValue=${searchValue}`,
  update_ageband_data: `${BASE_URL}broker/update-live-count?id=`,
  exclusion_list: `${BASE_URL}broker/exclusion-list?code=`,
  submit_add_policy: `${BASE_URL}broker/policy`,
  get_policy_detail_view: `${BASE_URL}broker/policy-tab-view?id=`,
  get_policy_details: `${BASE_URL}broker/policy-detail-view?id=`,
  check_grid_criteria: `${BASE_URL}broker/check-is-grade-criteria-fullfilled?id=`,
  update_policy_details: `${BASE_URL}broker/policy?id=`,
  update_live_count: `${BASE_URL}broker/update-live-count?id=`,
  add_policy_notes: `${BASE_URL}broker/policy-notes?id=`,
  policy_attachment: `${BASE_URL}broker/policy-attachment`,
  get_policy_tax: `${BASE_URL}broker/tax-rates/list-view?limit=100&skip=0&sort=1&search=`,
  get_tax_dropdown: `${BASE_URL}broker/tax-rates/dropdown-list`,
  update_policy_status: (policyId: string) =>
    `${BASE_URL}broker/policy-status?id=${policyId}`,
    download_ecard: (policyId: string,userType:string) =>
    `${BASE_URL}${userType}/download-bulk-ecard?policyId=${policyId}`,
  networkHospitalList: (tpa: string) =>
    `https://3c1f-203-134-205-106.ngrok-free.app/api/${tpa}/v1/hospital_networks`,
  getNetworkHospitalsList: (tpa: string, policyNumber: string) =>
    `${BASE_URL}global/network-hospital?tpaName=${tpa}&policy_number=${policyNumber}`,

  //quotes
  get_quotes_list: (
    limit: number,
    skip: number,
    starDate: any,
    endDate: any,
    searchValue: any
  ) =>
    `${BASE_URL}broker/quotes?limit=${limit}&skip=${skip}&startDate=${starDate}&endDate=${endDate}&searchValue=${searchValue}`,
  submit_add_quotes: `${BASE_URL}broker/quotes`,
  get_quotes_details: `${BASE_URL}broker/quotes-detail-view?id=`,
  get_status: `${BASE_URL}global/status/quote`,
  get_status_member: `${BASE_URL}global/status/member`,
  get_status_enrolment: `${BASE_URL}global/status/enrolment`,
  get_status_nominee: `${BASE_URL}global/status/nominee-enrolment`,
  get_employee_dependant_list: (
    userType: string,
    url:string
  ) =>
    `${BASE_URL}${userType}/policy-member-list?${url}`,
  get_status_employement: `${BASE_URL}global/status/employment`,
  get_custom_dropDown:(user:string)=> `${BASE_URL}${user}/policy-grades/`,
  get_status_policy: `${BASE_URL}global/status/policy`,
  get_status_endorsement: `${BASE_URL}global/status/endorsement`,
  get_status_claim: `${BASE_URL}global/status/claim`,
  isStatusBeingUsed: `${BASE_URL}global/check-is-status-attached?id=`,
  isRoleBeingUsed: `${BASE_URL}auth/check-active-role?id=`,
  assignPolicy: (userType: string, id: string,selectedGrade:string) =>
    `${BASE_URL}${userType}/bulk-assign-policy?policyId=${id}&grade=${selectedGrade}`,
    dynamicFilter: (userType: string,clientId:string) =>
    `${BASE_URL}${userType}/dynamic-filter?clientId=${clientId}`,
    getFiltervaluesByName: (userType: string,filter:string,clientId:string) =>
    `${BASE_URL}${userType}/filter-values?filter=${filter}&clientId=${clientId}`,
  update_status: `${BASE_URL}broker/quote-status?id=`,
  get_quote_tab_data: (quoteId: string, tabName: string) =>
    `${BASE_URL}broker/quote-tab-view?id=${quoteId}&tab=${tabName}`,
  add_insurer_attachment: `${BASE_URL}broker/quote-insurer-attachment`,
  quote_delete_attachment: `${BASE_URL}broker/quote-attachment`,
  update_quotes_details: `${BASE_URL}broker/quote?id=`,
  add_quote_note: (quoteId: string) =>
    `${BASE_URL}broker/quote-note?id=${quoteId}`,
  add_quote_insurer: `${BASE_URL}broker/quote-add-insurer`,
  quote_individual_edit: (quoteId: string) =>
    `${BASE_URL}broker/quote-indvidual-edit?id=${quoteId}`,
  add_quote_attachment: `${BASE_URL}broker/quote-attachment`,

  // claim
  create_claim: `${BASE_URL}broker/client/claim`,
  get_claim_list:  (user: string, limit: number, skip: number, search: string) =>
    `${BASE_URL}${user}/client/claim?limit=${limit}&skip=${skip}&searchValue=${search}`,
  claim_status_change: `${BASE_URL}broker/client/claim/status?id=`,
  add_items: `${BASE_URL}broker/update-claim-detail?claimId=`,
  get_claim_document: `${BASE_URL}broker/client/claim/doc/`,
  get_employee_by_client: `${BASE_URL}broker/employee-dropdown-list?clientId=`,
  get_policy_by_employe: `${BASE_URL}broker/employee-policy-dropdown-view/`,
  get_active_policy_by_employe: `${BASE_URL}broker/employee-active-policy-dropdown-view/`,
  get_claim_detail_data: (user: string) =>
    `${BASE_URL}${user}/client/claim-detail-view?id=`,
  get_physical_form_data: (
    user: string,
    id: string,
    policyId: string,
    claimtype: string
  ) =>
    `${BASE_URL}${user}/insurer-physical-form?id=${id}&policyId=${policyId}&type=${claimtype}`,
  get_tpa_check_list: (user: string) => `${BASE_URL}${user}/tpa-check-list?id=`,
  get_tpa_check_list_employee: `${BASE_URL}employee/tpa-check-list?id=`,
  claim_individual_edit: `${BASE_URL}broker/client/claim-indvidual-edit?id=`,
  claim_add_notes: (id: string, usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/client/claim-notes?id=${id}`,
  claim_attachment: (usertype: any) =>
    `${BASE_URL}${usertype}/claim-attachment`,
  delete_attachment_claim: `${BASE_URL}broker/claim-attachment`,

  //UGTable
  get_selected_columns: (code: number | undefined, userType: string) =>
    `${BASE_URL}${userType}/filter-data-list?code=${code}`,
  save_selected_columns: (code: number | undefined, userType: string) =>
    `${BASE_URL}${userType}/filter-data?code=${code}`,

  //Employee Policy
  get_employee_assignable_policies: (clientId: string) =>
    `${BASE_URL}broker/employee-policy-list-view?id=${clientId}`,
  get_dep_assignable_policies: (empId: string) =>
    `${BASE_URL}broker/dependant-assignable-policies?id=${empId}`,
  assign_employee_policies: (empId: string) =>
    `${BASE_URL}broker/employee-assign-policy?employeeId=${empId}`,
  get_assigned_policies: (empId: string) =>
    `${BASE_URL}broker/employee-policy-view?employeeId=${empId}`,
  add_member_to_policy: (
    empId: string,
    code: number,
    isDep: string,
    usertypeinfo: string
  ) =>
    `${BASE_URL}${usertypeinfo}/assign-member-to-policy?employeeId=${empId}&code=${code}&is_dep=${isDep}`,
  get_members: (empId: string, usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/member-dropdown-list?employeeId=${empId}`,
  get_nominees: (empId: string, usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/nominee-dropdown-list?employeeId=${empId}`,
  deassign_employee_policy: (empId: string, relationId: string) =>
    `${BASE_URL}broker/employee-deassign-policy?employeeId=${empId}&id=${relationId}`,
  create_policy_ecard: (empId: string, usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/assign-ecard-to-policy?employeeId=${empId}`,
  policy_entity_deassign: (
    type: PolicyDeassignEntityType,
    empId: string,
    policyId: string,
    relationId: string,
    usertypeinfo: string
  ) =>
    `${BASE_URL}${usertypeinfo}/deassign?type=${type}&employeeId=${empId}&policyId=${policyId}&id=${relationId}`,
  create_nominee_in_policy: (
    empId: string,
    usertypeinfo: string,
    code?: string
  ) =>
    `${BASE_URL}${usertypeinfo}/assign-nominee-to-policy?employeeId=${empId}&code=${
      code || "0"
    }`,

  post_cd_balance: `${BASE_URL}broker/policy-ledger?id=`,

  assign_deassign_nominee_policy: (empId: string, code: string) =>
    `${BASE_URL}broker/assign-deassign-nominee-to-policy?employeeId=${empId}&code=${code}`,
  policy_grade_dropdown: (policyId: string, userType: string) =>
    `${BASE_URL}${userType}/policy-grade-dropdown?id=${policyId}`,
  get_dependent_assigned_policies: (empId: string, depId: string) =>
    `${BASE_URL}broker/employee-dependent-policy-view?employeeId=${empId}&memberId=${depId}`,
  CreateEndorsementForNominee: (userType: string) =>
    `${BASE_URL}${userType}/endorsment`,
  get_cd_ledger: `${BASE_URL}broker/policy-ledger?id=`,

  //Support
  create_support_ticket: `${BASE_URL}broker/support-data`,
  get_support_ticket: `${BASE_URL}broker/support-data-list`,

  // homebroker || dashboard api url
  // get_count_of_homebroker: `${BASE_URL}broker/dashboard-count-data`,
  // get_policy_graph_homebroker: `${BASE_URL}broker/dashboard-policy-graph-count`,
  // dashboard_claim_graph: `${BASE_URL}broker/dashboard-claim-graph-count`,
  // broker dashboard - sahil
  broker_dashboard_stats: `${BASE_URL}broker/dashboard-stats`,

  // bulk upload api url
  contact_get_unique_field_data: `${BASE_URL}broker/contact-upload-unique-field-view`,
  client_unique_field_data: `${BASE_URL}broker/client-upload-unique-field-view`,
  member_unique_field_data: (user: string) =>
    `${BASE_URL}${user}/${
      user === "broker" ? "employee" : "member"
    }-upload-unique-field-view`,
  product_config_insurer_unique_field_data: `${BASE_URL}broker/product/insurance-upload-unique-field-view`,

  dependent_policy_config_check: (
    policyId: string,
    selectedRelationship: string,
    empId: string,
    user: string
  ) =>
    `${BASE_URL}${user}/employee-policy-config-check?policyId=${policyId}&value=${selectedRelationship}&employeeId=${empId}`,

  //Settings Module - Currencies
  add_currency_entry: `${BASE_URL}broker/currency`,
  list_currency_entries: (currencyCode: string) =>
    `${BASE_URL}broker/currency-list?baseCurrency=${currencyCode}`,
  delete_currency_entry: (currencyId: string) =>
    `${BASE_URL}broker/currency?id=${currencyId}`,
  update_currency_entry: (currencyId: string) =>
    `${BASE_URL}broker/currency?id=${currencyId}`,

  get_employer_critical_cd_alerts: `${BASE_URL}employer/client-critical-cd-alerts`,
  employer_dashboard_stats: `${BASE_URL}employer/dashboard`,
  employer_active_policies: `${BASE_URL}employer/active-policies`,
  // get_broker_critical_cd_alerts: `${BASE_URL}broker/dashboard-critical-cd-alerts`,
  //Appreance - setting
  save_appearance_data: (user: string) => `${BASE_URL}${user}/appearence `,

  // endorsement
  get_endorsement_list_view: (user: string, limit: number, skip: number, search: string) =>
    `${BASE_URL}${user}/endorsment?limit=${limit}&skip=${skip}&searchValue=${search}`,
  get_member_detail: (id: string, usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/member-details?id=${id}`,
  get_member_dependents: (user: string) =>
    `${BASE_URL}${user}/dependent-dropdown-list`,
  add_endorsement: (usertypeinfo: string) =>
    `${BASE_URL}${usertypeinfo}/endorsment`,
  endorsement_details_view: (userType: string) =>
    `${BASE_URL}${userType}/endorsment-detail-view?id=`,
  endorsement_indivisual_edit: `${BASE_URL}broker/endorsment-indvidual-edit?id=`,
  // endorsement_upload_attachment: `${BASE_URL}broker/endorsment-attachment`,
  endorsement_upload_attachment: (userType: string) =>
    `${BASE_URL}${userType}/endorsment-attachment`,
  endorsement_notes: (user: string) => `${BASE_URL}${user}/endorsment-notes`,
  endorsement_member_addition_notes: (id: string, user: string) =>
    `${BASE_URL}${user}/endorsment-notes?id=${id}`,
  endorsement_status_change: `${BASE_URL}broker/endorsment-status?id=`,
  getDetaisForMemberNomineeAdditionDeletion: (user: string) =>
    `${BASE_URL}${user}/endorsment-detail-view-nominee-member?id=`,
  broker_path: (userType: string) => `${BASE_URL}${userType}/path`,
  endorsement_suppoting_docs_post: (userType: string) =>
    `${BASE_URL}${userType}/endorsment-attachment`,
  delete_attachment_endorsement: (userType: string) =>
    `${BASE_URL}${userType}/endorsment-attachment`,
  get_retail_policies: (userType: string) =>
    `${BASE_URL}${userType}/retail-insurance`,
  get_email_data: (userId: string, settingId: string) => `${BASE_URL}broker/apply-settings?user_id=${userId}&setting_id=${settingId}&apply_for=EMAIL`,
  get_email_dropdown_data: () => `${BASE_URL}broker/mail-config-dropdown`,
  get_email_dropdown_data_details: (id: any) =>
    `${BASE_URL}broker/config-mail?id=${id}`,
  update_mail_config_settings: `${BASE_URL}broker/config`,
  getSignedUrl: (userType: string) => `${BASE_URL}${userType}/get-link`
};
