import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SetPasswordDTO } from "../../../../../Services/DTO/Employee/EmployeeEnrolmentDTO";
import { EMPLOYEE_ENROLMENT_SERVICES } from "../../../../../Services/Employee/EmployeeEnrolmentService";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import "../EnrollmentConfirmation/EnrollmentConfirmation.scss";
import { checkPasswordStrength, validateMobileNumber } from "../../../../../Supporting files/HelpingFunction";
import useIsMobile from "../../../../../Supporting files/MobileProvider";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

interface Props {
  value_update: Function;
  userName: string;
  mob: string;
  email: string;
  userId: string;
  apiLoading: boolean;
  setApiLoading: any;
}

const EnrollmentConfirmation: React.FC<Props> = ({
  value_update,
  userName,
  mob,
  email,
  userId,
  apiLoading,
  setApiLoading,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(mob);
  const [createPassword, setCreatePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValidityObj, setPasswordValidityObj] = useState<{
    isValid: boolean;
    containsNameOrEmail: boolean;
    hasMinLength: boolean;
    hasSymbolOrNumber: boolean;
  }>({
    containsNameOrEmail: false,
    hasMinLength: false,
    hasSymbolOrNumber: false,
    isValid: false,
  });
  const [trueConditions, setTrueConditions] = useState(0);
  const isMobile = useIsMobile();
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordCheck, setPasswordCheck] = useState({
    lengthValid: {
      isValid: false,
      message: "",
    },
    hasNumber: {
      isValid: false,
      message: "",
    },
    hasUppercase: {
      isValid: false,
      message: "",
    },
    hasSpecialChar: {
      isValid: false,
      message: "",
    },
    noForbiddenSequence: {
      isValid: false,
      message: "",
    },
    noUsername: {
      isValid: false,
      message: "",
    },
    noMobileNumber: {
      isValid: false,
      message: "",
    },
    passwordsMatch: {
      isValid: false,
      message: "",
    },
    noOldPasswordMatch: {
      isValid: false,
      message: "",
    },
  });
  const [passwordWarn, setPasswordWarn] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  console.log("password check",passwordCheck)
  // useEffect(() => {
  //   isValidPassword();
  // }, [createPassword]);
  useEffect(() => {
    let report = checkPasswordStrength(
      password.newPassword,
      password.confirmPassword,
      "",
      "",
     "",
      false
    );
    setPasswordCheck(report);
  }, [password]);

  // const updateMasterState = (attrName: any, value: any) => {
  //   attrName(value);
  // };
  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[0];
    let mainkey = attrName[1];
    if (key === "password") {
      setPassword({ ...password, [mainkey]: value });
      setPasswordWarn({ ...passwordWarn, [mainkey]: false });
    }
    if (key === "phone") {
      setPhoneNumber(value)
    }
  };


  const onSubmitPassword = () => {
    const allValid =
      passwordCheck.lengthValid.isValid &&
      passwordCheck.hasNumber.isValid &&
      passwordCheck.hasUppercase.isValid &&
      passwordCheck.hasSpecialChar.isValid &&
      passwordCheck.noForbiddenSequence.isValid &&
      passwordCheck.noUsername.isValid &&
      passwordCheck.noMobileNumber.isValid &&
      passwordCheck.passwordsMatch.isValid &&
      passwordCheck.noOldPasswordMatch.isValid;
    console.log("password",password.newPassword,password.confirmPassword)

    if (allValid) {
      setApiLoading(true);


      const onSuccess = (res: any) => {
        value_update("setStartSeventhScreen", true);
        setApiLoading(false);
      };
      const onError = (err: any) => {
        console.log("err", err);
        setApiLoading(false);
      };
  
      const dto: SetPasswordDTO = {
        confirmPassword:password.confirmPassword,
        password: password.newPassword,
        recoveryPhoneNumber: phoneNumber,
      };
  
      EMPLOYEE_ENROLMENT_SERVICES.set_password(onSuccess, onError, userId, dto);
    }
  };

  return (
    <>
      {isMobile ? (
        <Box className="enrollmentConfirmation_wrapper">
          <Grid container spacing={3} className="row">
            <Grid xs={12} className="mb-5">
              <div className="MenrollmentBackBtn">
                <ArrowBackIosOutlinedIcon fontSize="small"
                  onClick={() => {
                    value_update("setStartSixthScreenfalse", false);
                  }}
                  
                />
                <h5>Back</h5>
              </div>
            </Grid>

           
            <Grid xs={12} className="password_container pl-0 pr-0 pb-5 pt-0 mb-6 my-10 ">
              {/* <h5>
                Hey {userName}, Create your password <br /> for JIO EB Portal
              </h5> */}
              <div>
                <h5> Hey {userName}</h5>
                <h4 className="label">Create your password  for EB Portal</h4>
              </div>
              <Grid xs={12}>
                <Box width="100%">
                  <Grid container padding={0}>
                    <Grid xs={12} className="mb-4">
                      <RKTextField
                        class_name="inputField"
                        title="Recovery Phone Number For Password"
                        type="phone"
                        value={phoneNumber}
                        attrName={["phone"]}
                        value_update={updateMasterState}
                        warn_status={!validateMobileNumber(phoneNumber)}
                        charcterlimit={10}
                        validation_type="numeric"
                        disabled
                        error_messg={
                          phoneNumber.length === 0
                            ? "Enter Phone Number"
                            : "Enter valid Phone Number"
                        }
                      />
                    </Grid>
                    <Grid xs={12} className="mb-4">
                      <RKTextField
                        type="password"
                        password_status
                        class_name="inputField"
                        title="Create Password"
                        value={password.newPassword}
                        attrName={["password", "newPassword"]}
                        value_update={updateMasterState}
                        warn_status={passwordWarn.newPassword}
                        error_messg={
                          password.newPassword?.length > 0 &&
                            password.newPassword === password.oldPassword
                            ? "Old Password & New Password should't be same"
                            : "Enter New Password"
                        }
                      />
                    </Grid>
                    <Grid xs={12} className="mb-4">
                      <RKTextField
                        type="password"
                        password_status
                        class_name="inputField"
                        title="Confirm Password"
                        value={password.confirmPassword}
                        attrName={["password", "confirmPassword"]}
                        value_update={updateMasterState}
                        warn_status={passwordWarn.confirmPassword}
                        error_messg={
                          confirmPassword.length > 0
                            ? "Confirm Password must match"
                            : "Enter Confirm Password"
                        }
                      />
                    </Grid>
                    <Grid xs={12} className="mb-4">
                      <div className="confirmation_content">
                        {/* <p>
                          Password Strength:{" "}
                          {trueConditions >= 3 ? (
                            <span>Strong</span>
                          ) : trueConditions <= 1 ? (
                            <span style={{ color: "red" }}>Weak</span>
                          ) : (
                            <span style={{ color: "yellow" }}>Medium</span>
                          )}
                        </p> */}
                        <ul>
                      <li className={passwordCheck.lengthValid.isValid ? "success" : "error"}>
                        Password must be at least 8 characters long
                      </li>
                      <li className={passwordCheck.hasNumber.isValid && passwordCheck.hasUppercase.isValid && passwordCheck.hasSpecialChar.isValid ? "success" : "error"}>
                        Password must contain at least one uppercase letter, number, and special character
                      </li>
                      <li className={passwordCheck.noForbiddenSequence.isValid ? "success" : "error"}>
                        Password should not contain the sequence "12345678"
                      </li>
                      <li className={passwordCheck.noMobileNumber.isValid ? "success" : "error"}>
                        Password should not contain your mobile number
                      </li>
                      <li className={passwordCheck.passwordsMatch.isValid ? "success" : "error"}>
                        Password and Confirm Password must be same
                      </li>
                      {/* {!isMainBroker && <li className={passwordCheck.noOldPasswordMatch.isValid ? "success" : "error"}>
                        New password cannot be the same as the old password
                      </li>} */}
                        </ul>
                      </div>
                    </Grid>
                    {/* <Grid xs={12} textAlign={"center"}>
                      <img src="mobile/password_girl.svg" alt="" className="mb-15"/>
                    </Grid> */}
                    <Grid xs={12} textAlign={"center"}>
                      <div className="MenrollmentStartBtn  ">
                        <Button
                          variant="contained"
                          className="submitBtn"
                          onClick={() => {
                            if (apiLoading) {
                              return;
                            }
                            onSubmitPassword();
                          }}
                          disabled={apiLoading}
                        >
                          Submit
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="enrollmentConfirmation_wrapper">
          <Grid container spacing={3} className="row">
            <Grid xs={12} className="mb-5">
              <div className="backBtn">
                <ArrowBackIcon
                  onClick={() => {
                    value_update("setStartSixthScreenfalse", false);
                  }}
                />
                <span>Back to Enrolment Confirmation</span>
              </div>
            </Grid>

            <Grid xs={12}>
              <div className="confirmation_section">
                <div className="confirmation_innerSection">
                  <h5>
                    Hey {userName}, Create your password <br /> for JIO EB
                    Portal
                  </h5>
                  <hr />
                  <Grid container spacing={3}>
                    <Grid xs={4} className="text-right">
                      <img
                        src="./images/enrollment_confirmation_img.svg"
                        alt=""
                        height={429}
                      />
                    </Grid>
                    <Grid xs={8}>
                      <Grid container spacing={3}>
                        <Grid xs={9}>
                          <RKTextField
                            class_name="inputField"
                            title="Recovery Phone Number For Password"
                            type="phone"
                            value={phoneNumber}
                            attrName={["phone"]}
                            value_update={updateMasterState}
                            warn_status={!validateMobileNumber(phoneNumber)}
                            charcterlimit={10}
                            validation_type="numeric"
                            disabled
                            error_messg={
                              phoneNumber.length === 0
                                ? "Enter Phone Number"
                                : "Enter valid Phone Number"
                            }
                          />
                        </Grid>
                        <Grid xs={9}>
                        <RKTextField
                        type="password"
                        password_status
                        class_name="inputField"
                        title="Create Password"
                        value={password.newPassword}
                        attrName={["password", "newPassword"]}
                        value_update={updateMasterState}
                        warn_status={passwordWarn.newPassword}
                        error_messg={
                          password.newPassword?.length > 0 &&
                            password.newPassword === password.oldPassword
                            ? "Old Password & New Password should't be same"
                            : "Enter New Password"
                        }
                      />
                        </Grid>
                        <Grid xs={9}>
                        <RKTextField
                        type="password"
                        password_status
                        class_name="inputField"
                        title="Confirm Password"
                        value={password.confirmPassword}
                        attrName={["password", "confirmPassword"]}
                        value_update={updateMasterState}
                        warn_status={passwordWarn.confirmPassword}
                        error_messg={
                          confirmPassword.length > 0
                            ? "Confirm Password must match"
                            : "Enter Confirm Password"
                        }
                      />
                        </Grid>
                        <Grid xs={12}>
                          <div className="confirmation_content">
                            {/* <p>
                              Password Strength:{" "}
                              {trueConditions >= 3 ? (
                                <span>Strong</span>
                              ) : trueConditions <= 1 ? (
                                <span style={{ color: "red" }}>Weak</span>
                              ) : (
                                <span style={{ color: "yellow" }}>Medium</span>
                              )}
                            </p> */}
                            <ul>
                      <li className={passwordCheck.lengthValid.isValid ? "success" : "error"}>
                        Password must be at least 8 characters long
                      </li>
                      <li className={passwordCheck.hasNumber.isValid && passwordCheck.hasUppercase.isValid && passwordCheck.hasSpecialChar.isValid ? "success" : "error"}>
                        Password must contain at least one uppercase letter, number, and special character
                      </li>
                      <li className={passwordCheck.noForbiddenSequence.isValid ? "success" : "error"}>
                        Password should not contain the sequence "12345678"
                      </li>
                      <li className={passwordCheck.noMobileNumber.isValid ? "success" : "error"}>
                        Password should not contain your mobile number
                      </li>
                      <li className={passwordCheck.passwordsMatch.isValid ? "success" : "error"}>
                        Password and Confirm Password must be same
                      </li>
                      {/* {!isMainBroker && <li className={passwordCheck.noOldPasswordMatch.isValid ? "success" : "error"}>
                        New password cannot be the same as the old password
                      </li>} */}
                        </ul>
                            <div className="ctaBtn mb-5">
                              <Button
                                variant="contained"
                                className="submitBtn"
                                onClick={() => {
                                  if (apiLoading) {
                                    return;
                                  }
                                  onSubmitPassword();
                                }}
                                disabled={apiLoading}
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default EnrollmentConfirmation;
